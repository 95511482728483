/** @jsx jsx */
import { jsx, css } from "@emotion/core"

// ==================================
// = Utility Components & Functions =
// ==================================

type Props = {
  width?: number
  height?: number
}

/**
 * A simple component to add empty space between flex items
 */
export const Spacer: React.FC<Props> = ({ width, height }) => (
  <div css={{ minWidth: width, minHeight: height }} />
)

export const column = css({
  display: "flex",
  flexDirection: "column",
})

export const row = css({
  display: "flex",
})

export const flexCenterChildren = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
})

export const fullWidth = css({ width: "100%" })
export const fullHeight = css({ height: "100%" })
export const fullWidthAndHeight = css({ width: "100%", height: "100%" })

/**
 * Sets the vertical (y-axis) gap between consecutive children for this container.
 *
 * Only works inside a flex container where all of the children are DOM nodes (no strings) and none
 * of them have margins on them (which they shouldn't since padding is a better alternative)
 */
export const yGap = (distance: number) =>
  css({
    "& > *": {
      marginBottom: distance,
    },

    "& > *:last-child": {
      marginBottom: 0,
    },
  })

/**
 * Sets the vertical (y-axis) gap between consecutive children for this container.
 *
 * Only works inside a flex container where all of the children are DOM nodes (no strings) and none
 * of them have margins on them (which they shouldn't since padding is a better alternative)
 */
export const xGap = (distance: number) =>
  css({
    "& > *": {
      marginRight: distance,
    },

    "& > *:last-child": {
      marginRight: 0,
    },
  })
