/** @jsx jsx */
import { jsx } from "@emotion/core"
import { darken, lighten } from "polished"
import { Color } from "./constants"

const Button: React.FC<JSX.IntrinsicElements["button"]> = ({ ...props }) => (
  <button
    css={{
      height: 55,
      background: Color.Green,
      boxShadow: "0 5px 15px 0 rgba(0, 195, 137, 0.70)",
      border: "none",
      borderRadius: 5,
      margin: 0,
      fontSize: 16,
      color: "#FFFFFF",
      cursor: "pointer",
      padding: "0 20px",

      transition: "background-color 0.25s ease",

      "&:hover": {
        backgroundColor: lighten(0.02, Color.Green),
      },

      "&:active": {
        backgroundColor: darken(0.02, Color.Green),
      },

      "&:focus": {
        outline: "none",
      },
    }}
    {...props}
  />
)

export default Button
