/** @jsx jsx */
import { css } from "@emotion/core"
import { Color } from "./constants"

const siteWideStyles = css({
  html: {
    boxSizing: "border-box",
    height: "100%",
  },
  "*, *:before, *:after": {
    boxSizing: "inherit",
  },
  body: {
    fontFamily: "'Nunito Sans', sans-serif",
    color: Color.DarkGray,
    height: "100%",
  },
  "#root": {
    minHeight: "100%",
    width: "100%",
  },
  "html, body, #root": {
    width: "100%",
    height: "100%",
  },
  a: {
    color: Color.Green,
    textDecoration: "none",
  },
  p: {
    margin: 0,
    padding: 0,
  },
  "p + p": {
    marginTop: 10,
  },
})

export default siteWideStyles
