import { css } from "@emotion/core";
import { Color } from "./constants";

const baseInputFieldStyles = css({
  padding: "0 5px",
  border: `1px solid ${Color.MediumGray}`,
  borderRadius: 5,
  width: "100%",
  height: 40,
  fontSize: 14,
  fontWeight: "bold",
  textAlign: "right",

  "&:focus": {
    outline: "none",
  },
});

export default baseInputFieldStyles;
