/** @jsx jsx */
import { ClassNames, jsx } from "@emotion/core"
import { RouteComponentProps } from "@reach/router"
import Prismic from "prismic-javascript"
import { RichText } from "prismic-reactjs"
import React, { useEffect, useState } from "react"
import ReactTooltip from "react-tooltip"
import baseInputFieldStyles from "./baseInputFieldStyles"
import { Color, MediaQuery, siteContentContainer } from "./constants"
import CurrencyInput from "./CurrencyInput"
import { EditableCalculatorContent } from "./EditableContent.types"
import ExpandableDetails from "./ExpandableDetails"
import HubSpotEmailForm from "./HubSpotEmailForm"
import { Spacer } from "./layoutUtilities"
import prismicClient from "./prismicClient"
import { Helmet } from "react-helmet"

const InfoIcon = () => (
  <svg width={16} height={16} style={{ display: "block" }}>
    <path
      d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 3.333a.667.667 0 1 1 0 1.334.667.667 0 0 1 0-1.334zm1.5 9.334h-3a.666.666 0 1 1 0-1.334h.833v-4h-.5a.667.667 0 1 1 0-1.333H8c.369 0 .667.299.667.667v4.666H9.5a.666.666 0 1 1 0 1.334z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
)

const ContextualHelpIcon = () => (
  <svg width={25} height={25}>
    <path
      d="M12.5 0C19.393 0 25 5.607 25 12.5S19.393 25 12.5 25 0 19.393 0 12.5 5.607 0 12.5 0zm0 1.5c-6.066 0-11 4.934-11 11s4.934 11 11 11 11-4.934 11-11-4.934-11-11-11zm0 16.697a1.042 1.042 0 1 1 0 2.083 1.042 1.042 0 0 1 0-2.083zm0-13.542a4.693 4.693 0 0 1 4.688 4.687c0 1.408-1.221 3.477-3.04 4.315-.368.17-.606.542-.606.947v.468a1.041 1.041 0 1 1-2.084 0v-.468c0-1.216.715-2.33 1.818-2.84 1.062-.488 1.828-1.785 1.828-2.422A2.607 2.607 0 0 0 12.5 6.738a2.607 2.607 0 0 0-2.604 2.604 1.042 1.042 0 1 1-2.084 0A4.693 4.693 0 0 1 12.5 4.655z"
      fill="#00C389"
      fillRule="nonzero"
    />
  </svg>
)

type TooltipPlacement = "top" | "right" | "bottom" | "left" | undefined

const Tooltip: React.FC<{ id: string; placement?: TooltipPlacement }> = ({
  id,
  children,
  placement,
}) => (
  <ClassNames>
    {({ css }) => (
      <ReactTooltip
        id={id}
        place={placement}
        type="light"
        effect="solid"
        border
        // @ts-ignore -- this isn't in the typedefs but it is supported per the
        // react-tooltip docs
        borderColor="rgba(0, 0, 0, 0.2)"
        className={css({
          backgroundColor: "white",
          maxWidth: 250,
          boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)",
        })}
      >
        {children}
      </ReactTooltip>
    )}
  </ClassNames>
)

type MonetaryAmountInputFieldRowProps = {
  name: string
  description: string
  helpMessage: React.ReactNode
  value: number | null
  onChange: (updatedValue: number | null) => void
}

const MonetaryAmountInputFieldRow: React.FC<MonetaryAmountInputFieldRowProps> = (props) => (
  <div css={{ display: "flex", alignItems: "center", width: "100%" }}>
    <div css={{ fontSize: 14, width: "100%", maxWidth: 400, paddingRight: 10 }}>
      {props.description}
    </div>
    <div
      css={{
        width: "100%",
        maxWidth: 300,
        position: "relative",

        "&::after": {
          content: "'$'",
          fontWeight: "bold",
          position: "absolute",
          top: "50%",
          left: 10,
          transform: "translateY(-50%)",
        },
      }}
    >
      <CurrencyInput
        name={props.name}
        value={props.value ?? ""}
        onChange={(event) => {
          // Strip out any commas and parse the resulting number
          const parsedValue = parseFloat(event.target.value.replace(/[,]/g, ""))
          if (Number.isFinite(parsedValue)) {
            props.onChange(parsedValue)
          } else {
            props.onChange(null)
          }
        }}
        css={[baseInputFieldStyles, { textAlign: "right", fontWeight: "bold" }]}
      />
    </div>
    <Spacer width={10} />
    <div data-tip data-for={props.name} css={{ cursor: "help" }}>
      <ContextualHelpIcon />
      <Tooltip id={props.name} placement="right">
        {props.helpMessage}
      </Tooltip>
    </div>
  </div>
)

const ExtraInfo: React.FC = ({ children }) => (
  <div css={{ width: "100%", maxWidth: "60%", [MediaQuery.Mobile]: { maxWidth: "70%" } }}>
    {children}
  </div>
)

type ManualEntryEstimatorProps = RouteComponentProps & {}

const ManualEntryEstimator: React.FC<ManualEntryEstimatorProps> = () => {
  const [adjustedEmployeeEarnings, setAdjustedEmployeeEarnings] = useState<number | null>(null)
  const [amountOver100kToEmployees, setAmountOver100kToEmployees] = useState<number | null>(null)
  const [stateTaxAmount, setStateTaxAmount] = useState<number | null>(null)
  const [benefitsCostAmount, setBenefitsCostAmount] = useState<number | null>(null)
  const [employeeCount, setEmployeeCount] = useState<number | null>(null)
  const [isNewBusiness, setIsNewBusiness] = useState<boolean | null>(null)
  const [editableContent, setEditableContent] = useState<EditableCalculatorContent>()

  useEffect(() => {
    const fetchAndSetEditableContent = () => {
      prismicClient
        .query(Prismic.Predicates.at("document.type", "calculator"), {})
        .then(({ results: [content] }) =>
          setEditableContent((content?.data as EditableCalculatorContent) ?? null),
        )
    }
    fetchAndSetEditableContent()

    // Refresh the content every 5 minutes in case folks leave the page open
    const contentAutoRefreshInterval = setInterval(() => fetchAndSetEditableContent, 5 * 60 * 1000)

    return () => {
      clearInterval(contentAutoRefreshInterval)
    }
  }, [])

  const [emailPromptIsVisible, setEmailPromptIsVisible] = useState(false)

  // Show the email prompt only after they've been on the page for 30 seconds
  useEffect(() => {
    setTimeout(() => {
      setEmailPromptIsVisible(true)
    }, 15 * 1000)
  }, [])

  // Estimate calculation
  let totalQualifyingAmount: number | null = null

  if (
    adjustedEmployeeEarnings !== null &&
    isNewBusiness !== null &&
    amountOver100kToEmployees !== null &&
    stateTaxAmount !== null &&
    benefitsCostAmount !== null &&
    employeeCount !== null
  ) {
    const runningTotal =
      adjustedEmployeeEarnings - amountOver100kToEmployees + stateTaxAmount + benefitsCostAmount

    if (isNewBusiness) {
      totalQualifyingAmount = (runningTotal * 2.5) / 2
    } else {
      totalQualifyingAmount = (runningTotal * 2.5) / 12
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>PPP Loan Calculator</title>
        <meta
          name="description"
          content="Calculate how much government Stimulus money your business can get. Finish your application in minutes."
        />
      </Helmet>

      {editableContent ? (
        <div
          css={[
            siteContentContainer,
            { display: "flex", justifyContent: "center", padding: "0 80" },
          ]}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: 1000,
              paddingTop: 50,
              paddingBottom: 100,
            }}
          >
            {/* Header and instructions */}
            <div css={{ fontSize: 40 }}>Loan Calculator</div>
            <Spacer height={20} />

            <div css={{ fontSize: 18 }}>
              <RichText render={editableContent.preface} />
            </div>

            <Spacer height={50} />
            <div css={{ fontSize: 26 }}>
              {RichText.asText(editableContent.instructions_callout)}
            </div>
            <Spacer height={20} />

            {/* FAQ */}
            <ExpandableDetails title="What timeframe should I select?">
              <ExpandableDetails title="Most businesses:  April 1, 2019 - March 31, 2020">
                For businesses that were in operation the selected pay period should be 12 full
                months from the report date (for example, if today were April 3rd, 2020, we
                recommend generating a report from April 1st, 2019 to March 31st, 2020 to get a
                complete picture of your monthly average payroll costs). You may also want to run a
                report for Calendar year 2019 (Jan 1, 2019 - December 31, 2019) in case your lender
                prefers that.
              </ExpandableDetails>
              <Spacer height={10} />
              <ExpandableDetails title="New businesses (not in operation before 2/15/2020): January 1, 2020 - February 29, 2020">
                A business is considered new if it wasn't operational and{" "}
                <strong>
                  A business is considered new if it wasn't operational and paying employees by
                  February 15th, 2019 A business is considered new if it wasn't operational and{" "}
                </strong>
                . If this describes your business, use the pay period{" "}
                <strong>January 1st, 2020 - February 29th, 2020.</strong>
              </ExpandableDetails>
              <Spacer height={10} />
              <ExpandableDetails title="Seasonal businesses: check with your accountant">
                Seasonal employers with higher-than-average payroll costs during the "covered
                period" (February 15th - June 30th) should select{" "}
                <strong>February 15th, 2019 - June 30th, 2019</strong>.
              </ExpandableDetails>
            </ExpandableDetails>

            <Spacer height={20} />

            <ExpandableDetails title="What should I include in the report?">
              <p>
                Once you’ve selected the timeframe, you’ll want to include the total compensation
                paid to your employees; this includes employees’ adjusted earnings which you add to
                the employer’s (but not employee’s) portion of state and local taxes, health
                benefits, and 401(k) or other retirement benefits. You can use the grand totals from
                the report(s) for the time period you selected. You only need totals to be broken
                down by employee for employees earning over $100k in annual salary. Gusto has
                provided a PPP-specific report. With other providers, you may have to use the
                "payroll journal" and/or "payroll allocation report" and/or "benefits report" or
                similar. <strong>Download and save all reports.</strong>
              </p>
              <p>
                <strong>Please note</strong>: Despite guidance prior to 04/03/2020, as of 4/5/2020
                payments to Independent Contractors (1099s) do not count as payroll costs; however,
                some lawmakers such as{" "}
                <a
                  href="https://www.rubio.senate.gov/public/index.cfm/2020/4/rubio-releases-update-on-paycheck-protection-program-nearly-5-billion-in-relief-approved"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Marco Rubio
                </a>{" "}
                want to reverse this again so stay tuned to resubmit if they do. They may also add
                back in federal taxes from the employee and employer which would further simplify
                things and make your loan bigger.{" "}
                <a
                  href="https://home.treasury.gov/system/files/136/PPP--IFRN%20FINAL.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  This document
                </a>{" "}
                is final word for now.
              </p>
            </ExpandableDetails>

            <Spacer height={20} />

            <ExpandableDetails
              title={
                "Do I need anything else besides my payroll " +
                "report to calculate total loan amounts?"
              }
            >
              <p>
                Yes, you need to know the <strong>number of employees</strong> you had as of{" "}
                <strong>February 15, 2020</strong>.
              </p>
              <p>
                To be safe and your bank will probably require it, you should also find or request
                from your payroll provider IRS Form 941s for all four quarters of 2019 and Q1'2020.{" "}
              </p>
              <p>
                In addition, if the answer is <strong>yes</strong> to{" "}
                <em>any of the following questions</em>, you will need the information following the
                question to calculate your loan amount. If the answer is no, continue to the
                calculator.
              </p>
              <Spacer height={10} />
              <ExpandableDetails title="Did you pay any of your employees more than $100k salary per year?">
                You'll need to subtract the total wages (+bonus +commissions, etc) over $100k for
                any single employee that was compensated more than $100k per year.
              </ExpandableDetails>
              <Spacer height={10} />
              <ExpandableDetails title="Did you apply and receive an Economic Injury Disaster Loan (EIDL) related to losses due to COVID-19?">
                If you received a loan, you can add that amount to this loan. This does not include
                the $10k "advance" so if you received that only, do not add.
              </ExpandableDetails>
              <Spacer height={10} />
              <ExpandableDetails title="The name, title, address and SSN or EIN of every person or entity who owns more 20% of your company? - this is only for the application, not calculating loan">
                You don't need this to calculate loans amounts but you do need it for the
                application. If anyone or company owns more than 20% of your company and that person
                also likely owns more than 20% of another company, you'll want to check with your
                lawyer on affiliation. SAFEs and convertible notes that haven't converted to equity
                yet, don't count.
              </ExpandableDetails>
            </ExpandableDetails>

            <Spacer height={60} />

            <div css={{ width: "100%", fontWeight: "bold", textAlign: "left" }}>
              All fields are required. Enter "0" if the value for a field is zero.
            </div>

            <Spacer height={20} />

            {/* Form fields */}
            <MonetaryAmountInputFieldRow
              name="adjustedEmployeeEarnings"
              description="Adjusted Employee Earnings"
              helpMessage="Total on a payroll report"
              value={adjustedEmployeeEarnings}
              onChange={setAdjustedEmployeeEarnings}
            />

            <Spacer height={10} />

            <MonetaryAmountInputFieldRow
              name="amountOver100kToEmployees"
              description="Amounts Paid Over $100k to  Employees"
              helpMessage="Salary + commissions + bonus amounts over $100k"
              value={amountOver100kToEmployees}
              onChange={setAmountOver100kToEmployees}
            />

            <Spacer height={10} />

            <MonetaryAmountInputFieldRow
              name="stateTaxAmount"
              description="Employer State/Local Taxes"
              helpMessage="All taxes paid to the state or city by the employer only"
              value={stateTaxAmount}
              onChange={setStateTaxAmount}
            />

            <Spacer height={10} />

            <MonetaryAmountInputFieldRow
              name="benefitsCostAmount"
              description="Employer Portion of Benefits"
              helpMessage="All benefits including 401 match paid by the employer (not employee). "
              value={benefitsCostAmount}
              onChange={setBenefitsCostAmount}
            />

            <Spacer height={10} />

            <div css={{ width: "100%", display: "flex", alignItems: "center" }}>
              <div css={{ width: "100%", maxWidth: 400, paddingRight: 10 }}>
                Number of employees
              </div>
              <div css={{ width: "100%", maxWidth: 300 }}>
                <input
                  type="number"
                  name="employeeCount"
                  css={baseInputFieldStyles}
                  min={1}
                  max={99999}
                  step={1}
                  value={employeeCount ?? ""}
                  onChange={(event) => {
                    // Give it an effective min of 1 and max length of 5 since
                    // min, maxLength, and max attributes don't work as desired
                    const parsedValue = parseInt(event.target.value.slice(0, 5), 10)
                    if (Number.isFinite(parsedValue)) {
                      setEmployeeCount(Math.abs(parsedValue))
                    } else {
                      setEmployeeCount(null)
                    }
                  }}
                />
              </div>

              <Spacer width={10} />

              <div data-tip data-for="employeeCountHelp">
                <ContextualHelpIcon />
                <Tooltip id="employeeCountHelp" placement="right">
                  Employee count at 2/15/20
                </Tooltip>
              </div>
            </div>

            <Spacer height={10} />

            <div css={{ width: "100%", display: "flex", alignItems: "center", height: 40 }}>
              <div css={{ width: "100%", maxWidth: 400, paddingRight: 10 }}>
                Were you in business before 2/15/2019?
              </div>
              <div
                css={{ width: "100%", maxWidth: 300, display: "flex", justifyContent: "flex-end" }}
              >
                <label>
                  <input
                    type="radio"
                    name="isNewBusiness"
                    checked={isNewBusiness === true}
                    onChange={() => setIsNewBusiness(true)}
                  />{" "}
                  No
                </label>
                <Spacer width={20} />
                <label>
                  <input
                    type="radio"
                    name="isNewBusiness"
                    checked={isNewBusiness === false}
                    onChange={() => setIsNewBusiness(false)}
                  />{" "}
                  Yes
                </label>
              </div>

              <Spacer width={10} />

              {/* There's no tooltip for this field at the moment but this is left in
          to ensure everything aligns perfectly and in case it is useful in the future */}
              <div data-tip data-for="employeeCountHelp" css={{ visibility: "hidden" }}>
                <ContextualHelpIcon />
                <Tooltip id="employeeCountHelp" placement="right">
                  Employee count at 2/15/20
                </Tooltip>
              </div>
            </div>

            <Spacer height={50} />

            {/* Result */}
            <div css={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <div css={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                <div css={{ display: "flex", justifyContent: "flex-end" }}>
                  <div css={{ fontWeight: "bold", fontSize: 16 }}>Total Eligibility Amount</div>
                  <Spacer width={10} />
                  <div data-tip data-for="totalInfo">
                    <InfoIcon />
                  </div>
                  <Tooltip id="totalInfo" placement="top">
                    This amount is calculated by taking the avarage of your total payroll costs over
                    the number of months you selected and multiplying that total by 2.5. To
                    calculate average payroll for the SBA loan form divide this amount by 2.5.
                  </Tooltip>
                </div>
                <div css={{ fontWeight: 900, fontSize: 36 }}>
                  <span css={{ color: Color.DarkGray }}>$</span>{" "}
                  <span css={{ color: Color.Green }}>
                    {totalQualifyingAmount
                      ? new Intl.NumberFormat("en-US").format(Math.floor(totalQualifyingAmount))
                      : "TBD"}
                  </span>
                </div>
              </div>
            </div>

            <Spacer height={50} />

            {/* Extra info */}
            <ExtraInfo>
              <div css={{ fontSize: 18 }}>
                <RichText render={editableContent.extra_information} />
              </div>
            </ExtraInfo>

            {/* <ExtraInfo>
          <strong>Are you an independent contractor or sole proprietor? </strong> Then you only need
          your total earnings from your 1099s.
        </ExtraInfo>

        <Spacer height={10} />

        <ExtraInfo>
          <strong>Not sure where to get this information?</strong> Contact your payroll provider or
          accountant for additional support.
        </ExtraInfo>

        <Spacer height={40} />

        <ExtraInfo>
          Download the{" "}
          <a
            href="https://s3.us-west-2.amazonaws.com/secure.notion-static.com/378f8b0b-fe93-4bd4-bf54-e042a486bfaa/Paycheck-Protection-Program-Application-3-30-2020-v3_%281%29.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAT73L2G45O3KS52Y5%2F20200406%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20200406T011119Z&X-Amz-Expires=86400&X-Amz-Signature=eabdd0a23e656c34e453801cdb46814c225d04fd1930d7ea4b15cedccf0b42e7&X-Amz-SignedHeaders=host&response-content-disposition=filename%20%3D%22Paycheck-Protection-Program-Application-3-30-2020-v3%2520%281%29.pdf%22"
            target="_blank"
            rel="noopener noreferrer"
          >
            official loan application form here
          </a>
          .
        </ExtraInfo>

        <Spacer height={40} />

        <ExtraInfo>
          We also put together a checklist of other documentation that might be useful for
          completing any loan applications. Check it out{" "}
          <a
            href="https://www.notion.so/PPP-Loan-Calculator-and-Application-de4750d6aed3452286aa25138182897e"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </ExtraInfo>

        <Spacer height={40} />

        <ExtraInfo>
          <span css={{ fontWeight: "bold" }}>Disclaimer:</span>This calculator provides an estimate
          of the amount you could be eligible for. While we created this calculator with the{" "}
          <a
            href="https://home.treasury.gov/system/files/136/PPP--IFRN%20FINAL.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            official SBA guidance
          </a>{" "}
          on this, we cannot guarantee that these are the precise numbers you need for the loan
          application and ask that you verify these amounts with your accountant or payroll provider
          before submitting your loan application.
        </ExtraInfo> */}
          </div>
          <div
            css={{
              position: "fixed",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bottom: 0,
              left: 0,
              width: "100%",
              height: 175,
              // Transition it in slowly but out quickly
              transition: emailPromptIsVisible ? "transform 1s ease" : "transform 0.3s ease",
              transform: emailPromptIsVisible ? "translateY(0)" : "translateY(100%)",
            }}
          >
            <div
              css={{
                width: "100%",
                height: "100%",
              }}
            >
              <HubSpotEmailForm onDismiss={() => setEmailPromptIsVisible(false)} />
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  )
}

export default ManualEntryEstimator
