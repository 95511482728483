/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import { useState } from "react"
import baseInputFieldStyles from "./baseInputFieldStyles"
import { Color } from "./constants"
import { Spacer } from "./layoutUtilities"

// from https://stackoverflow.com/questions/5639346
function getCookieValue(name: string) {
  var match = document.cookie.match("(^|[^;]+)\\s*" + name + "\\s*=\\s*([^;]+)")
  return match ? match.pop() : ""
}

type FormState = "unsubmitted" | "submitting" | "done" | "error"

type Props = {
  onDismiss: () => void
}

const HubSpotEmailForm: React.FC<Props> = (props) => {
  const [email, setEmail] = useState<string>()
  const [formState, setFormState] = useState<FormState>("unsubmitted")
  const hubSpotPortalId = "7479158"
  const formId = "c908b4ce-aa27-4349-be52-4ba14e3a1759"

  return (
    <div
      css={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Color.Green,
        color: "white",
        padding: "0 20px",
      }}
    >
      {formState !== "done" ? (
        <React.Fragment>
          <form
            onSubmitCapture={(event) => {
              setFormState("submitting")
              event.preventDefault()
              console.log("form submitted", email)
              fetch(
                `https://api.hsforms.com/submissions/v3/integration/submit/${hubSpotPortalId}/${formId}`,
                {
                  method: "POST",
                  // mode: "cors",
                  headers: {
                    "Content-Type": "application/json",
                    accept: "application/json",
                  },
                  body: JSON.stringify({
                    fields: [{ name: "email", value: email }],
                    context: {
                      pageUri: window.location.href,
                      ...(getCookieValue("hubspotutk")
                        ? { hutk: getCookieValue("hubspotutk") }
                        : {}),
                    },
                  }),
                },
              ).then((response) => {
                // Decode the response so it shows up in devtools
                response.text()
                if (response.status < 300) {
                  setFormState("done")
                  setTimeout(() => props.onDismiss(), 2 * 1000)
                } else {
                  setFormState("error")
                }
              })
            }}
          >
            <div
              css={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                css={{
                  fontSize: 24,
                  textAlign: "center",
                  textShadow: "0 2px 2px rgba(0, 0, 0, 0.22)",
                }}
              >
                Stay updated on beneficial programs for small businesses and contractors
              </div>
              <Spacer height={20} />
              <div css={{ display: "flex" }}>
                <input
                  css={[
                    baseInputFieldStyles,
                    {
                      textAlign: "left",
                      minWidth: 250,
                      "&[disabled]": { opacity: 0.7, cursor: "disabled" },
                    },
                  ]}
                  type="email"
                  placeholder="Email"
                  disabled={formState === "submitting"}
                  required
                  onChange={(event) => setEmail(event.target.value)}
                />
                <Spacer width={10} />
                <button
                  css={{
                    height: 40,
                    whiteSpace: "nowrap",
                    padding: "0 20px",
                    borderRadius: 5,
                    backgroundColor: "transparent",
                    border: "2px solid white",
                    color: "white",
                    fontSize: 18,
                    textShadow: "0 2px 1px rgba(0, 0, 0, 0.1)",
                    cursor: "pointer",
                    "&[disabled]": {
                      opacity: 0.7,
                      cursor: "disabled",
                    },
                    "&:focus": { outline: "none" },
                  }}
                  type="submit"
                  disabled={formState === "submitting"}
                >
                  Keep Me Updated
                </button>
              </div>

              {formState === "error" && (
                <div
                  css={{
                    paddingTop: 10,
                    fontSize: 14,
                    color: "white",
                  }}
                >
                  Please double check your email and try submitting again.
                </div>
              )}
            </div>
          </form>
          <div
            css={{
              position: "absolute",
              right: 0,
              top: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 75,
              color: "rgba(255, 255, 255, 0.8)",
              fontSize: 40,
              fontWeight: 100,
              cursor: "pointer",
            }}
            onClick={props.onDismiss}
          >
            X
          </div>
        </React.Fragment>
      ) : (
        <div css={{ width: "100%", textAlign: "center", fontSize: 24 }}>
          Thanks for subscribing! We'll keep you updated with information as we receive it.
        </div>
      )}
    </div>
  )
}

export default HubSpotEmailForm
