import React from "react";
import MaskedInput, { MaskedInputProps } from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const mask = createNumberMask({
  prefix: "",
  // suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2,
  integerLimit: 12,
  allowNegative: false,
  allowLeadingZeroes: false,
});

const CurrencyInput: React.FC<MaskedInputProps> = (props) => {
  return <MaskedInput mask={mask} inputMode="numeric" {...props} />;
};

export default CurrencyInput;
