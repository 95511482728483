/** @jsx jsx */
import { jsx } from "@emotion/core"
import { RouteComponentProps } from "@reach/router"
import { transparentize } from "polished"
import React from "react"
import { MediaQuery, siteContentContainer } from "./constants"
import charlesBioImageUrl from "./images/charles.jpg"
import jeremyBioImageUrl from "./images/jeremy.jpg"
import lauraBioImageUrl from "./images/laura.jpg"
import mikeBioImageUrl from "./images/mike.jpg"
import naomiBioImageUrl from "./images/naomi.jpg"
import { column, flexCenterChildren, fullWidth, row, Spacer, xGap, yGap } from "./layoutUtilities"

type BioItemProps = {
  name: string
  bio: string
  imageUrl: string
  color: string
  mirrored?: boolean
  linkedInUrl: string
}

const LinkedInIcon = () => (
  <svg width={80} height={80}>
    <g fill="none" fillRule="evenodd">
      <circle fill="currentColor" cx={40} cy={40} r={40} />
      <path
        d="M29.337 32.196c.746 0 1.35.605 1.35 1.351V57.65A1.35 1.35 0 0 1 29.338 59h-5.75a1.35 1.35 0 0 1-1.35-1.35V33.546c0-.746.604-1.35 1.35-1.35h5.75zm21.575-.404c9.655 0 9.82 8.815 9.823 13.949v12.017c0 .686-.556 1.242-1.242 1.242h-6.17a1.242 1.242 0 0 1-1.241-1.242V46.453c0-1.687.494-7.39-4.408-7.39-3.802 0-4.574 3.904-4.728 5.656v13.04c0 .685-.556 1.241-1.242 1.241h-5.968a1.242 1.242 0 0 1-1.241-1.242v-24.32c0-.686.556-1.242 1.241-1.242h5.968c.686 0 1.242.556 1.242 1.242v2.103c1.41-2.116 3.505-3.75 7.966-3.75zM26.462 19a5.462 5.462 0 1 1 0 10.924 5.462 5.462 0 0 1 0-10.924z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

const BioItem: React.FC<BioItemProps> = (props) => (
  <div
    css={[
      row,
      {
        flexDirection: props.mirrored ? "row-reverse" : "row",
        justifyContent: "space-between",
        [MediaQuery.Mobile]: {
          flexDirection: "column-reverse",
        },
      },
    ]}
  >
    {/* Bio */}
    <div
      css={[
        column,
        yGap(20),
        {
          width: "100%",
          color: "white",
          position: "relative",
          [MediaQuery.Mobile]: { width: "100%" },
        },
      ]}
    >
      <div
        css={[
          column,
          yGap(20),
          {
            padding: 50,
            borderRadius: 30,
            backgroundColor: props.color,
            boxShadow: `${props.mirrored ? "-2px" : "2px"} 4px 25px ${transparentize(
              0.5,
              props.color,
            )}`,
          },
        ]}
      >
        <div css={{ fontSize: 26 }}>{props.name}</div>
        <div
          css={{
            fontSize: 18,
            lineHeight: "30px",
            whiteSpace: "pre-line",
            // Makes the text a little clearer against the bright colored background
            textShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
            [MediaQuery.Mobile]: {
              fontSize: 16,
              lineHeight: "22px",
            },
          }}
        >
          {props.bio}
        </div>
      </div>
    </div>

    {/* Photo */}
    <div
      css={[
        row,
        flexCenterChildren,
        {
          alignItems: "center",
          justifyContent: "center",
          ...(props.mirrored ? { paddingRight: 50 } : { paddingLeft: 50 }),
          [MediaQuery.Mobile]: {
            width: "100%",
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 20,
          },
        },
      ]}
    >
      <div
        css={{
          width: 250,
          height: 250,
          borderRadius: "50%",
          border: "1px solid #999",
          boxShadow: `${props.mirrored ? "-2px" : "2px"} 4px 25px ${transparentize(
            0.5,
            props.color,
          )}`,
          position: "relative",
        }}
      >
        <a
          href={props.linkedInUrl}
          target="_blank"
          rel="noopener noreferrer"
          css={[
            { position: "absolute", bottom: 0, color: props.color },
            props.mirrored ? { right: 0 } : { left: 0 },
          ]}
        >
          <LinkedInIcon />
        </a>
        <img
          src={props.imageUrl}
          alt=""
          css={{ width: "100%", height: "100%", borderRadius: "50%" }}
        />
      </div>
    </div>
  </div>
)

const AboutPage: React.FC<RouteComponentProps> = (props) => (
  <div css={siteContentContainer}>
    <div css={[siteContentContainer, column, { padding: "80px 0" }]}>
      <div
        css={[
          row,
          xGap(20),
          {
            alignItems: "center",
            [MediaQuery.Mobile]: { flexDirection: "column", justifyContent: "center" },
          },
        ]}
      >
        <div
          css={[
            column,
            yGap(20),
            {
              width: "100%",
              [MediaQuery.Mobile]: { alignItems: "center", textAlign: "center", paddingBottom: 20 },
            },
          ]}
        >
          <div css={{ fontSize: 40 }}>About 7Commas, Inc.</div>
          <div css={{ fontSize: 20, lineHeight: "36px" }}>
            7Commas is a small business helping other small businesses understand their finances. We
            realize that even the smartest founders don't have time or resources to dedicate to
            optimizing their finances and the playing field is often uneven. At this time, we have
            dedicated all of our resources to making sure small businesses get the help they need,
            fast.
          </div>
        </div>
        <div css={{ width: "100%" }}>
          <div
            css={{
              position: "relative",
              overflow: "hidden",
              paddingBottom: `56.25%`,
            }}
          >
            <div css={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
              <iframe
                title="video"
                css={{ width: "100%", height: "100%", border: 0 }}
                src="https://www.youtube.com/embed/smHGlVj8bqs"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </div>

      <Spacer height={100} />

      <div css={[fullWidth, { fontSize: 40, textAlign: "center" }]}>Our Team</div>

      <Spacer height={50} />

      <div css={[column, yGap(100)]}>
        <BioItem
          name="Laura Bilazarian"
          bio={`Laura is a 3rd time social entrepreneur who has dedicated her life to leveling the playing field in business.  She’s been a venture-backed founder and VC partner (twice). Still, she can’t stop herself from trying to build the software she wishes she could use to automate the less fun, but mission critical, parts of being a founder.

          Previously, she worked on wall street to pay off her student loans and fund her USA Rugby National Team training - which is why she knows how all this stuff operates and wants to protect creators and builders from predatory finance practices.`}
          linkedInUrl="https://www.linkedin.com/in/laurabilazarian/"
          color="#7796FE"
          imageUrl={lauraBioImageUrl}
        />

        <BioItem
          mirrored
          name="Naomi Mastico"
          bio={`Naomi is a digital native and policy nerd who would rather be working to save the environment than in a finance software company, but she joined Seven Commas to learn how to do things quickly, and understand how finance works to bring that knowledge back to the nonprofit sector.  She hopes that facilitating getting this money into the hands of small businesses as soon as possible will save jobs across the board.`}
          linkedInUrl="https://www.linkedin.com/in/nmastico/"
          color="#CA7CC0"
          imageUrl={naomiBioImageUrl}
        />

        <BioItem
          name="Charles Lee"
          bio={`Charles has worked in startups across e-commerce, SaaS, mobile apps, big data analytics, and others, but still tries to keep well-rounded.  He has long-held passions for the physics of parkour, and the chemistry of mixology - and is happy to teach any of them!  Charles encourages you to make small businesses out of your passions.`}
          linkedInUrl="https://www.linkedin.com/in/chaqke/"
          color="#8937DC"
          imageUrl={charlesBioImageUrl}
        />

        <BioItem
          mirrored
          name="Mike Sela"
          bio={`Mike has been a programmer for decades, starting even before his bar mitzvah. He has led teams and projects in companies both big and small. Mike finds much satisfaction in analyzing data, writing back-end services, and building React front-ends. He is delighted to be helping Seven Commas help small businesses. Mike fills his spare time by spinning tales of awkwardness at story-telling events like The Moth.`}
          linkedInUrl="https://www.linkedin.com/in/mikesela/"
          color="#7CCA84"
          imageUrl={mikeBioImageUrl}
        />

        <BioItem
          name="Jeremy Yun"
          bio={`Jeremy climbs rocks, drinks whiskey on the rocks, and generally likes to rock. He joined his first tech startup before the web existed and has helped develop all kinds of software over the last 20+ years. A reformed mechanical engineer, he relishes the freedom from physical constraints that working with code affords him. He’s excited to be collaborating with Seven Commas to bring some relief to the many small businesses in need.`}
          linkedInUrl="https://www.linkedin.com/in/jeremyyun/"
          color={"#77BFFE"}
          imageUrl={jeremyBioImageUrl}
        />

        {/* <BioItem
          mirrored
          name="Nick Artman"
          bio={`TODO`}
          linkedInUrl="https://www.linkedin.com/in/nicolasartman/"
          color="#FF865B"
          imageUrl={TODO}
        /> */}
      </div>
    </div>
  </div>
)

export default AboutPage
