import { RouteComponentProps } from "@reach/router";
import qs from "qs";
import { useEffect } from "react";
import prismicClient from "./prismicClient";

const linkResolver = (document: any) =>
  document.uid === "homepage" ? "/" : `/${document.uid ?? ""}`;

const PrismicPreviewRedirector: React.FC<RouteComponentProps> = ({ navigate }) => {
  useEffect(() => {
    const { token, documentId } = qs.parse(window.location.search.slice(1));
    if (!token) {
      return console.warn(`No token available, check your configuration`);
    }
    prismicClient
      .getPreviewResolver(token, documentId)
      .resolve(linkResolver, "/")
      .then((url) => (navigate ? navigate(url) : null));
  });
  return null;
};

export default PrismicPreviewRedirector;
