import { css } from "@emotion/core"

// Screen widths for media queries
const wideScreenMin = 1024
// const narrowScreenMax = wideScreenMin - 1;
const narrowScreenMin = 768
const tabletMax = narrowScreenMin - 1
const tabletMin = 640
const phoneMax = tabletMin - 1

export const MediaQuery = {
  WideScreenOnly: `@media (min-width: ${wideScreenMin}px)`,
  LaptopsAndDesktops: `@media (min-width: ${narrowScreenMin}px)`,
  Mobile: `@media (max-width: ${tabletMax}px)`,
  Tablet: `@media (min-width: ${tabletMin}px) and (max-width: ${tabletMax})px`,
  Phone: `@media (max-width: ${phoneMax}px)`,
}

export enum Color {
  Green = "#00C389",
  MediumGray = "#999",
  DarkGray = "#333333",
}

/**
 * This should be applied to the header and any top-level page content containers
 */
export const siteContentContainer = css({
  maxWidth: 1280,
  margin: "auto",
  paddingLeft: 40,
  paddingRight: 40,
  [MediaQuery.Mobile]: {
    paddingLeft: 20,
    paddingRight: 20,
  },
})
