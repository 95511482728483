/** @jsx jsx */
import { jsx } from "@emotion/core"
import { useState } from "react"

const DisclosureTriangle = () => (
  <svg width={14} height={14} style={{ display: "block" }}>
    <g fill="none" fillRule="evenodd" transform="translate(4)">
      <path d="M-3 0h14v14H-3z" />
      <path
        d="M8 7c0 .25-.096.502-.288.693l-6.034 6.02a.984.984 0 0 1-1.39 0 .979.979 0 0 1 0-1.387L5.628 7 .287 1.674a.979.979 0 0 1 0-1.387.984.984 0 0 1 1.39 0l6.034 6.02A.976.976 0 0 1 8 7z"
        fill="#00C389"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

type ExpandableExplanationProps = {
  title: any
}

const ExpandableDetails: React.FC<ExpandableExplanationProps> = (props) => {
  const gutterWidth = 20
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  return (
    <div css={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div
        css={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        role="button"
        onClick={toggle}
      >
        <div
          css={{
            minWidth: gutterWidth,
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            css={{
              transition: "transform 0.25s ease",
              transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
            }}
          >
            <DisclosureTriangle />
          </div>
        </div>
        <div css={{ fontSize: 18, width: "100%" }}>{props.title}</div>
      </div>
      {isOpen && (
        <div css={{ paddingLeft: gutterWidth, paddingTop: 10, paddingBottom: 10 }}>
          {props.children}
        </div>
      )}
    </div>
  )
}

export default ExpandableDetails
