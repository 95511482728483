/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { Link } from "@reach/router"
import { siteContentContainer, Color } from "./constants"
import { xGap, yGap, column } from "./layoutUtilities"
import Logo from "./Logo"

const navigationItemStyles = css({
  textTransform: "uppercase",
  fontSize: 18,
})

const Header: React.FC = () => (
  <div
    css={[
      siteContentContainer,
      {
        zIndex: 1000000,
        width: "100%",
        height: 60,
        display: "flex",
        alignItems: "center",
      },
    ]}
  >
    {/* This logo container is manually shifted so its baseline aligns better with
        other text in the header */}
    <Link to="/" css={{ display: "block", width: "auto", position: "relative", top: 7 }}>
      <Logo />
    </Link>
    <div css={[{ width: "100%", display: "flex", justifyContent: "flex-end" }, xGap(20)]}>
      <div
        css={[
          navigationItemStyles,
          { "& > div": { display: "none" } },
          { position: "relative", "&:hover > div": { display: "block" } },
        ]}
      >
        <button
          css={[
            navigationItemStyles,
            {
              border: 0,
              padding: 0,
              margin: 0,
              color: Color.Green,
              cursor: "pointer",
              "&:hover, &:focus": { outline: "none" },
            },
          ]}
        >
          Resources
        </button>
        <div
          css={[
            {
              position: "absolute",
              top: "100%",
              left: -20,
              width: 220,
              paddingTop: 20,
            },
          ]}
        >
          <div
            css={[
              column,
              yGap(20),
              { padding: 20, backgroundColor: "white", boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)" },
            ]}
          >
            <div css={navigationItemStyles}>
              <Link to="/find-a-lender">Find a Lender</Link>
            </div>
            <div css={navigationItemStyles}>
              <Link to="/calculator">Loan Calculator</Link>
            </div>
          </div>
        </div>
      </div>
      <div css={navigationItemStyles}>
        <Link to="/about">About Us</Link>
      </div>
    </div>
  </div>
)

export default Header
