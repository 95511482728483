/** @jsx jsx */
import { Global, jsx } from "@emotion/core"
import { RouteComponentProps, Router } from "@reach/router"
import normalize from "emotion-normalize"
import React from "react"
import Header from "./Header"
import Homepage from "./Homepage"
import ManualEntryEstimator from "./ManualEntryEstimator"
import PrismicPreviewRedirector from "./PrismicPreviewRedirector"
import siteWideStyles from "./siteWideStyles"
import AboutPage from "./AboutPage"

const FindALenderRedirector: React.FC<RouteComponentProps> = () => {
  window.location.href = "https://sevencommas.typeform.com/to/WDaLpT"

  return null
}

type EmbeddedFramePageProps = RouteComponentProps & {
  url: string
}

const EmbeddedFramePage: React.FC<EmbeddedFramePageProps> = (props) => (
  <iframe
    // TODO: figure out why the calc() and vh hack is necessary
    css={{ width: "100%", height: "calc(100vh - 65px)", border: 0, padding: 0, margin: 0 }}
    frameBorder="0"
    src={props.url}
    title="Information for Independent Contractors"
  />
)

const App = () => {
  return (
    <div css={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
      <Header />
      <Router css={{ width: "100%", height: "100%" }} primary={false}>
        <Homepage default path="/" />
        <AboutPage path="about" />
        <ManualEntryEstimator path="calculator" />
        <FindALenderRedirector path="find-a-lender" />
        <EmbeddedFramePage
          path="for-independent-contractors"
          url="https://sevencommas.pages.ontraport.net/for-independent-contractors"
        />

        {/* For previewing edits through prismic */}
        <PrismicPreviewRedirector path="prismic-preview-redirector" />
      </Router>
      <Global styles={normalize} />
      <Global styles={siteWideStyles} />
    </div>
  )
}

export default App
